.new-info-wrapper {
  padding-left: 0px;
}

.basic-info {
  max-width: 600px;
}

.mapboxgl-ctrl-bottom-left,
.mapboxgl-ctrl-bottom-right .mapboxgl-ctrl-attrib {
  display: none !important;
}

.mapboxgl-control-container > div > div {
  box-shadow: none !important;
  border-radius: 4px 4px 0px 0px;
}

@media screen and (max-width: 1024px) {
  .form-basic-info {
    width: 100%;
  }
}

@media screen and (min-width: 1024px) {
  .basic-info {
    min-width: 600px;
    width: unset;
  }

  .basic-button {
    width: 50%;
  }
}

.input-number input {
  padding: 6px 12px;
}
