.minimap-box {
  z-index: 999;
  position: absolute;
  bottom: 16px;
  //   bottom: 75px;
  left: 25px;
  width: 75px;
  height: 75px;
  & > img {
    border: 2px solid #fff;
    border-radius: 8px;
    box-shadow: 0 1px 4px rgb(0 0 0 / 30%);
    width: 75px;
    height: 75px;
    cursor: pointer;
    &:hover {
      border: 3px solid #fff;
    }
  }
  .minimap-box_overlay {
    position: absolute;
    color: white;
    bottom: 2px;
    padding: 5px 8px;
    left: 2px;
    font-size: 10px;
    display: block;
    width: 71px;
    border-radius: 0 0 8px 8px;
    background: rgb(0, 0, 0);
    background: linear-gradient(0deg, rgba(0, 0, 0, 1) 0%, rgba(255, 255, 255, 0) 100%);
  }
  .list-type {
    margin-top: 10px;
  }
}

.type-map {
  max-width: 50px;
  & > img {
    border: 2px solid #fff;
    border-radius: 8px;
    box-shadow: 0 1px 4px rgb(0 0 0 / 30%);
    width: 50px;
    height: 50px;
    cursor: pointer;
  }
}
